import React, { useState, useEffect } from "react";
import { Navigate, Link, useParams, useLocation } from "react-router-dom";
import Loader from "../Components/Loader";
import axios from "axios";
import Alert from "../Components/Alert";
import {
  doSignInWithEmailAndPassword,
  doSignInWithGoogle,
} from "../firebase/auth";
import { useAuth } from "../Components/AuthContext.js";

const BASE_URL = process.env.REACT_APP_PUBLIC_BACKEND_URL;
const ResumeTailor = () => {
  const { docLinkCheck } = useParams();
  const [link, setLink] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [newGrad, setNewGrad] = useState(false);
  const [loading, setloading] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [suggestions, setSuggestions] = useState();
  const BASE_URL = process.env.REACT_APP_PUBLIC_BACKEND_URL;
  const { userLoggedIn, currentUser } = useAuth();

  useEffect(() => {
    console.log(location.pathname.split("/"));
    if (location.pathname.split("/").length > 5) {
      const docLink = location.pathname.replace("/resume/", "");
      setLink(docLink);
    }
  }, []);

  const tailorResume = async (e) => {
    e.preventDefault();

    if (jobDescription.length === 0 || jobDescription.length === 0) {
      setAlertType("error");
      setAlert("Please fill in all required fields.");
      return;
    }

    setloading(true);

    try {
      // Check if the user has already signed in with Google Docs permissions
      if (!userLoggedIn) {
        const user = await doSignInWithGoogle().catch((err) => {
          console.error(err);
          setAlertType("error");
          setAlert("An error ocurred while signing in. Please try again.");
          return null;
        });
      }

      if (!currentUser) {
        setAlertType("error");
        setAlert("500. An error occurred. Please try again.");
        return;
      }
      const body = {
        link,
        jobDescription,
        user: currentUser,
      };

      const response = await axios.post(
        `${BASE_URL}get_resume_suggestions`,
        body
      );
      setSuggestions(response.data.changes);
      setAlertType("success");
      setAlert(<>Suggestions received!</>);
    } catch (error) {
      console.error(error);
      setAlertType("error");
      setAlert(
        "404. An error occurred. Please make sure `resume-editor@penpal-mobile-887f9.iam.gserviceaccount.com has edit access to your resume"
      );
    }
    setloading(false);
  };

  if (loading) {
    return (
      <div className="w-full h-full flex flex-col pt-72 md:justify-center md:items-center p-8 gap-4">
        <Loader height={100} width={100} />
        <div className="self-center">
          <div>Hang tight, we’re doing the magic!</div>
        </div>
      </div>
    );
  } else if (suggestions) {
    return (
      <div className="w-full h-full flex justify-center items-center md:pt-12">
        <div className="md:bg-stone-50 rounded-lg md:w-2/3 h-full flex flex-col  md:justify-center md:items-center p-8 gap-4">
          <div className="text-3xl font-extralight self-center pb-8">
            Almost done...
          </div>
          <SuggestionsList
            suggestions={suggestions}
            link={link}
            setSuggestions={setSuggestions}
          />
        </div>
      </div>
    );
  } else
    return (
      <div className="w-full h-full relative flex flex-col  md:justify-center md:items-center p-8 gap-4">
        <div className="text-3xl font-extralight self-center pb-8">
          Let’s Polish Your Resume
        </div>
        <form
          className="flex flex-col bg-white-50  gap-8 h-full w-full  max-w-[800px] rounded-lg py-8 px-4 justify-start"
          onSubmit={tailorResume}
        >
          <div className="relative w-full">
            <label
              htmlFor="link"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Resume Link
            </label>
            <input
              id="link"
              name="link"
              type="text"
              placeholder="Drop your Google Doc link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="block w-full outline-none rounded-md border-.5  py-4 font-light  px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="relative w-full">
            <label
              htmlFor="description"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Add Job Description
            </label>
            <textarea
              id="job_description"
              name="job_description"
              type="text"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              placeholder={`- Coding experience with C# or .NET is a plus \n- Attention to detail and organizational skill \n- Interest in learning and working with new technologies 
                        `}
              className="block w-full min-h-[300px] rounded-md border-.5  py-4 px-3 text-gray-900 shadow-sm font-light ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
          {/* <fieldset>
          <legend className="sr-only">Type</legend>
          <div className="space-y-2">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="newGrad"
                  name="newGrad"
                  type="checkbox"
                  checked={newGrad}
                  onChange={(e) => setNewGrad(e.target.checked)}
                  aria-describedby="candidates-description"
                  className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="candidates" className=" text-gray-900">
                  New grad opportunities
                </label>{" "}
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="internship"
                  name="internship"
                  type="checkbox"
                  checked={internship}
                  onChange={(e) => setInternship(e.target.checked)}
                  aria-describedby="candidates-description"
                  className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="candidates" className=" text-gray-900">
                  Internship opportunities
                </label>{" "}
              </div>
            </div>
          </div>
        </fieldset> */}

          <button
            type="submit"
            className="px-2 py-4 bg-sky-700 text-white rounded-lg hover:bg-sky-600"
          >
            Match keywords
          </button>

          {alert && (
            <div className="my-4 w-full sm:flex sm:justify-center">
              <Alert
                type={alertType}
                messageBody=""
                messageHeader={alert}
                changeMessage={setAlert}
              />
            </div>
          )}
        </form>
      </div>
    );
};

const SuggestionsList = ({ suggestions, link, setSuggestions }) => {
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [loading, setloading] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const { userLoggedIn, currentUser } = useAuth();

  // Handle checkbox change
  const handleCheckboxChange = (suggestion) => {
    if (selectedSuggestions.includes(suggestion)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item !== suggestion)
      );
    } else {
      setSelectedSuggestions([...selectedSuggestions, suggestion]);
    }
  };

  const handleSubmit = async () => {
    if (selectedSuggestions.length === 0) {
      setAlertType("error");
      setAlert("Please select at least one suggestion.");
      return;
    }

    setloading(true);

    try {
      const body = {
        link,
        updates: selectedSuggestions,
        user: currentUser,
      };

      console.log(body);

      // Make the POST request
      const response = await axios.post(`${BASE_URL}update_resume`, body);

      // Check if the response status is 200 or the expected success status
      if (response.status === 200) {
        console.log(response.data); // Check the data from the backend

        setAlertType("success");
        setAlert(<>Resume updated!</>);
      } else {
        // If the status is not 200, treat it as an error
        setAlertType("error");
        setAlert("An error occurred. Please try again.");
      }
    } catch (error) {
      console.error(error);
      setAlertType("error");
      setAlert("An error occurred. Please try again.");
    }

    setloading(false);
  };

  return (
    <div className="w-full flex flex-col items-start justify-start max-w-[800px]">
      <div className="pb-4">Please select the changes you want applied</div>
      {suggestions.map((suggestion, index) => (
        <div
          key={index}
          className={`flex flex-row gap-4 items-center ${
            selectedSuggestions.includes(suggestion)
              ? "bg-sky-100"
              : "bg-stone-100"
          } p-4 rounded-lg mb-4`}
        >
          {/* Add a checkbox to select the suggestion */}
          <input
            type="checkbox"
            checked={selectedSuggestions.includes(suggestion)}
            onChange={() => handleCheckboxChange(suggestion)}
            className=""
          />
          <div className="flex flex-col bg-white-50 py-2 h-full w-full rounded-lg justify-start items-start">
            <div className="font-light line-through decoration-sky-600 decoration decoration-1">
              {suggestion.old_text}
            </div>
            <div className="font-light">{suggestion.new_text}</div>
          </div>
        </div>
      ))}
      <div
        className="text-sm text-stone-500 hover:text-stone-800 hover:cursor-pointer"
        onClick={() => setSelectedSuggestions(suggestions)}
      >
        Select all
      </div>
      <div className="w-full items-start justify-start">
        <div className="flex flex-row gap-4 w-full py-8">
          <button
            className="px-4 py-4 bg-gray-100 w-full  text-black rounded-lg hover:bg-gray-200"
            onClick={() => {
              setSuggestions("");
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-4 bg-sky-700 w-full text-white rounded-lg hover:bg-sky-600"
            onClick={(e) => {
              handleSubmit();
              e.preventDefault();
            }}
          >
            Apply changes
          </button>
        </div>
        {loading && (
          <div className=" flex flex-col md:justify-center md:items-center p-8 gap-4">
            <Loader height={70} width={70} />
            <div className="self-center">
              <div>Hang tight, we’re doing the magic!</div>
            </div>
          </div>
        )}
        {alert && (
          <div className="my-4 w-full sm:flex sm:justify-start">
            <Alert
              type={alertType}
              messageBody=""
              messageHeader={alert}
              changeMessage={setAlert}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumeTailor;
